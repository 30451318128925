import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { MenuModel } from 'src/app/modules/configurations/menus/model/menu.model';
import { UserModel } from 'src/app/modules/configurations/users/models/user.model';
import { isTextEmpty } from 'src/app/modules/core/utils/validators.util';

@Injectable({
    providedIn: 'root',
})
export class StoreService {
    private urlRecoveryKey: string = 'sesa_ps_url_recovery';
    private authTokenKey: string = 'sesa_ps_auth_token';
    private authUserKey: string = 'sesa_ps_auth_user';
    private menuListKey: string = 'sesa_ps_menu_list';
    private listaMenus: ReplaySubject<MenuModel[]> = new ReplaySubject<MenuModel[]>();

    private identificacionClienteKey: string = 'sesa_ps_identificacion_cliente';
    private identificacionCliente: ReplaySubject<string> = new ReplaySubject<string>();

    private cantidadRegistrosSise360Key: string = 'sesa_ps_cantidad_registros_sise360';
    private cantidadRegistrosSise360: ReplaySubject<number> = new ReplaySubject<number>();

    private cantidadRegistrosGrupoMasKey: string = 'sesa_ps_cantidad_registros_grupoMas';
    private cantidadRegistrosGrupoMas: ReplaySubject<number> = new ReplaySubject<number>();

    private cantidadRegistrosHubSpot0Key: string = 'sesa_ps_cantidad_registros_hubspot';
    private cantidadRegistrosHubSpot: ReplaySubject<number> = new ReplaySubject<number>();

    private cantidadRegistrosBpmKey: string = 'sesa_ps_cantidad_registros_bpm';
    private cantidadRegistrosBpm: ReplaySubject<number> = new ReplaySubject<number>();

    private cantidadRegistrosSiniestrosKey: string = 'sesa_ps_cantidad_registros_siniestros';
    private cantidadRegistrosSiniestros: ReplaySubject<number> = new ReplaySubject<number>();

    private cantidadRegistrosEtafashionKey: string = 'sesa_ps_cantidad_registros_etafashion';
    private cantidadRegistrosEtafashion: ReplaySubject<number> = new ReplaySubject<number>();

    private cantidadRegistrosRmKey: string = 'sesa_ps_cantidad_registros_rm';
    private cantidadRegistrosRm: ReplaySubject<number> = new ReplaySubject<number>();

    constructor() {}

    public cargarIdentificacion(identificacion: string) {
        localStorage.setItem(this.identificacionClienteKey, identificacion);
        this.identificacionCliente.next(identificacion);
    }

    public obtenerIdentificacion(): Observable<string> {
        const identificacion = localStorage.getItem(this.identificacionClienteKey) || '';
        this.identificacionCliente.next(identificacion);
        return this.identificacionCliente.asObservable();
    }

    public removerIdentificacion() {
        localStorage.removeItem(this.identificacionClienteKey);
        localStorage.removeItem(this.cantidadRegistrosSise360Key);
        localStorage.removeItem(this.cantidadRegistrosGrupoMasKey);
        localStorage.removeItem(this.cantidadRegistrosHubSpot0Key);
        localStorage.removeItem(this.cantidadRegistrosBpmKey);
        localStorage.removeItem(this.cantidadRegistrosSiniestrosKey);
        localStorage.removeItem(this.cantidadRegistrosEtafashionKey);
        localStorage.removeItem(this.cantidadRegistrosRmKey);

        this.identificacionCliente.next('');
    }

    public cargarCantidadDatosSise360(cantidad: number) {
        localStorage.setItem(this.cantidadRegistrosSise360Key, cantidad.toString());
    }

    public obtenerCantidadRegistrosSise360(): Observable<number> {
        const cantidadString = localStorage.getItem(this.cantidadRegistrosSise360Key) || '0';
        const cantidad = parseInt(cantidadString, 10);
        this.cantidadRegistrosSise360.next(cantidad);
        return this.cantidadRegistrosSise360.asObservable();
    }

    public cargarCantidadRegistrosGrupoMas(cantidad: number) {
        localStorage.setItem(this.cantidadRegistrosGrupoMasKey, cantidad.toString());
    }

    public obtenerCantidadRegistrosGrupoMas() {
        const cantidadString = localStorage.getItem(this.cantidadRegistrosGrupoMasKey) || '0';
        const cantidad = parseInt(cantidadString, 10);
        this.cantidadRegistrosGrupoMas.next(cantidad);
        return this.cantidadRegistrosGrupoMas.asObservable();
    }

    public cargarCantidadRegistrosHubSpot(cantidad: number) {
        localStorage.setItem(this.cantidadRegistrosHubSpot0Key, cantidad.toString());
    }

    public obtenerCantidadRegistrosHubSpot() {
        const cantidadString = localStorage.getItem(this.cantidadRegistrosHubSpot0Key) || '0';
        const cantidad = parseInt(cantidadString, 10);
        this.cantidadRegistrosHubSpot.next(cantidad);
        return this.cantidadRegistrosHubSpot.asObservable();
    }

    public cargarCantidadRegistrosBpm(cantidad: number) {
        localStorage.setItem(this.cantidadRegistrosBpmKey, cantidad.toString());
    }

    public obtenerCantidadRegistrosBpm() {
        const cantidadString = localStorage.getItem(this.cantidadRegistrosBpmKey) || '0';
        const cantidad = parseInt(cantidadString, 10);
        this.cantidadRegistrosBpm.next(cantidad);
        return this.cantidadRegistrosBpm.asObservable();
    }

    public cargarCantidadRegistrosSiniestros(cantidad: number) {
        localStorage.setItem(this.cantidadRegistrosSiniestrosKey, cantidad.toString());
    }

    public obtenerCantidadRegistrosSiniestros() {
        const cantidadString = localStorage.getItem(this.cantidadRegistrosSiniestrosKey) || '0';
        const cantidad = parseInt(cantidadString, 10);
        this.cantidadRegistrosSiniestros.next(cantidad);
        return this.cantidadRegistrosSiniestros.asObservable();
    }

    public cargarCantidadRegistrosEtafashion(cantidad: number) {
        localStorage.setItem(this.cantidadRegistrosEtafashionKey, cantidad.toString());
    }

    public obtenerCantidadRegistrosEtafashion() {
        const cantidadString = localStorage.getItem(this.cantidadRegistrosEtafashionKey) || '0';
        const cantidad = parseInt(cantidadString, 10);
        this.cantidadRegistrosEtafashion.next(cantidad);
        return this.cantidadRegistrosEtafashion.asObservable();
    }

    public cargarCantidadRegistrosRm(cantidad: number) {
        localStorage.setItem(this.cantidadRegistrosRmKey, cantidad.toString());
    }

    public obtenerCantidadRegistrosRm() {
        const cantidadString = localStorage.getItem(this.cantidadRegistrosRmKey) || '0';
        const cantidad = parseInt(cantidadString, 10);
        this.cantidadRegistrosRm.next(cantidad);
        return this.cantidadRegistrosRm.asObservable();
    }

    public setUrlRecovery(url: string): void {
        if (!isTextEmpty(url)) {
            localStorage.setItem(this.urlRecoveryKey, url);
        }
    }

    public getUrlRecovery(): string {
        const urlRecovery = localStorage.getItem(this.urlRecoveryKey) || '';
        return urlRecovery;
    }

    public setAuthToken(token: string): void {
        if (!isTextEmpty(token)) {
            localStorage.setItem(this.authTokenKey, token);
        }
    }

    public getAuthToken(): string {
        const token = localStorage.getItem(this.authTokenKey) || '';
        return token;
    }

    public setAuthUser(user: UserModel | null): void {
        if (user) {
            const json = JSON.stringify(user);
            localStorage.setItem(this.authUserKey, json);
        }
    }

    public getAuthUser(): UserModel | null {
        const textJson = localStorage.getItem(this.authUserKey) || '';
        if (!isTextEmpty(textJson)) {
            const user: UserModel = JSON.parse(textJson) || null;
            return user;
        }
        return null;
    }

    public setMenuList(menuList: MenuModel[]): void {
        const menuListJson = JSON.stringify(menuList);
        localStorage.setItem(this.menuListKey, menuListJson);
        this.listaMenus.next(menuList);
    }

    public getMenuList(): MenuModel[] {
        const textJson = localStorage.getItem(this.menuListKey) || '';
        if (!isTextEmpty(textJson)) {
            const menuList: MenuModel[] = JSON.parse(textJson) || [];
            return menuList;
        }
        return [];
    }

    public getMenuListSubscription(): Observable<MenuModel[]> {
        const textJson = localStorage.getItem(this.menuListKey) || '';
        if (!isTextEmpty(textJson)) {
            const menuList: MenuModel[] = JSON.parse(textJson) || [];
            this.listaMenus.next(menuList);
        } else {
            this.listaMenus.next([]);
        }
        return this.listaMenus.asObservable();
    }

    public removeAuthSession(): void {
        localStorage.removeItem(this.authTokenKey);
        localStorage.removeItem(this.authUserKey);
        localStorage.removeItem(this.menuListKey);
    }

    public isBroker(): boolean {
        const user = this.getAuthUser();
        if (user?.tipoInterfaz?.esCorredorRequerido) {
            return true;
        }
        return false;
    }
}
